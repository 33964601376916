import "./svg-sprite.scss";
import { ReactComponent as Sprite } from "./sprites.svg";

export function SvgSprite(): JSX.Element {
  return (
    <div className="svg-sprite">
      <Sprite />
    </div>
  );
}

export default SvgSprite;

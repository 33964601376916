import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import "./button.scss";

interface ButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  to?: string;
  href?: string;
  onSubmit?: () => void;
  onClick?: () => void;
  target?: React.HTMLAttributeAnchorTarget;
}

export function Button({ disabled, type, className, children, to, onSubmit, onClick, href, target }: ButtonProps) {
  if (href) {
    return (
      <a href={href} target={target}>
        <Button className={className}>{children}</Button>
      </a>
    );
  }

  if (to) {
    return (
      <Link to={to}>
        <Button className={className}>{children}</Button>
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      type={type}
      className={`button${disabled ? "--disabled" : ""} ${className} `}
      onSubmit={onSubmit}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

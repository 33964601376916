import React from "react";
import PropTypes from "prop-types";
import "./toggle-switch.scss";

interface ToggleSwitchProps {
  id: string;
  name?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  optionLabels?: string[];
  small?: boolean;
  disabled?: boolean;
}

const ToggleSwitch = ({ id, name, checked, onChange, optionLabels, small, disabled }: ToggleSwitchProps) => {
  function handleKeyPress(event: React.KeyboardEvent<HTMLLabelElement>) {
    if (event.keyCode !== 32) return;

    event.preventDefault();
    onChange(!checked);
  }

  return (
    <div className={"toggle-switch" + (small ? " small-switch" : "")}>
      <input
        type='checkbox'
        name={name}
        className='toggle-switch-checkbox'
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      {id ? (
        <label
          className='toggle-switch-label'
          tabIndex={disabled ? -1 : 1}
          onKeyDown={(e) => handleKeyPress(e)}
          htmlFor={id}
        >
          <span
            className={disabled ? "toggle-switch-inner toggle-switch-disabled" : "toggle-switch-inner"}
            data-yes={optionLabels && optionLabels[0]}
            data-no={optionLabels && optionLabels[1]}
            tabIndex={-1}
          />
          <span
            className={disabled ? "toggle-switch-switch toggle-switch-disabled" : "toggle-switch-switch"}
            tabIndex={-1}
          />
        </label>
      ) : null}
    </div>
  );
};

// Set optionLabels for rendering.
ToggleSwitch.defaultProps = {
  optionLabels: ["Yes", "No"],
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  optionLabels: PropTypes.array,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ToggleSwitch;

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import SvgSprite from "./components/svg-sprite/svg-sprite";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./components/layout/layout";
import ScrollToTop from "./utilities/scroll-to-top";
import { LoadingComponent } from "./components/loading-component/loading-component";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
      <Suspense fallback={<LoadingComponent />}>
        <Router>
          <Layout>
            <ScrollToTop />
            <App />
            <SvgSprite />
          </Layout>
        </Router>
      </Suspense>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

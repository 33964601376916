export const menuItems = [
  {
    id: 1,
    title: "Product",
    url: "/product",
  },
  {
    id: 2,
    title: "Pricing",
    url: "/pricing",
  },
  {
    id: 5,
    title: "Success Stories",
    url: "/success-stories",
  },
  {
    id: 3,
    title: "Resources",
    url: "/resources",
  },
  {
    id: 4,
    title: "Solutions",
    url: "/solutions",
  },
];

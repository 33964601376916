/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button } from "../../button/button";
import { Sidebar } from "../../side-bar/side-bar";
import { SvgIcon } from "../../svg-icon/svg-icon";
import { Logo } from "../../ui/logo";
import { menuItems } from "./menu-item/menu-item";
import "./navbar.scss";
import logo from "../../../global/icons/logo-mobile.svg";

export function Navbar() {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handler = (event: { target: any }): void => {
      if (open && ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
        window.onscroll = function () {};
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [open]);

  useEffect(() => {
    if (open) {
      const TopScroll = window.pageYOffset || document.documentElement.scrollTop;
      const LeftScroll = window.pageXOffset || document.documentElement.scrollLeft;
      // if scroll happens, set it to the previous value
      window.onscroll = function () {
        window.scrollTo(LeftScroll, TopScroll);
      };
      return;
    }

    window.onscroll = function () {};
  }, [open]);

  function onMenuButtonPress() {
    setOpen(!open);
  }

  return (
    <>
      <section className='navbar'>
        <div className='row'>
          <div className='col-3 navbar__logo'>
            <Link to='/'>
              <Logo />
            </Link>
          </div>
          <div className='col-6'>
            <ul className='navbar__menu-items'>
              {menuItems.map((menuItem) => (
                <NavLink
                  key={menuItem.id}
                  to={menuItem.url}
                  className={({ isActive }) => "item" + (!isActive ? "" : "--active")}
                >
                  {menuItem.title}
                </NavLink>
              ))}
            </ul>
          </div>
          <div className='col-3 navbar__buttons-container'>
            <Link to={"/join-now"}>
              <Button className='join-button'>
                <p className='join-button__button-text'>Join Now!</p>
              </Button>
            </Link>
          </div>
        </div>
      </section>
      <section className={`small-navbar${open ? "--active" : ""}`} ref={ref}>
        <div className='buttons'>
          <button className='small-navbar__menu-button' onClick={onMenuButtonPress}>
            <SvgIcon name='burger-menu' className='menu-icon' />
          </button>
          {!open && (
            <Link to={"/"} className='small-navbar__logo'>
              <img src={logo} alt='logo' />
            </Link>
          )}
        </div>
        {!open && (
          <Link to={"/join-now"}>
            <Button className='small-navbar__join-button'>
              <p className='button-text'>Join Now!</p>
            </Button>
          </Link>
        )}
        <Sidebar menuItems={menuItems} open={open} setOpen={setOpen} />
      </section>
    </>
  );
}

import React, { useState } from "react";
import "./cookies-modal.scss";
import logo from "../../../global/icons/logo.svg";
import close from "../../../global/icons/close_icon.svg";
import { Button } from "../../button/button";
import ToggleSwitch from "../../toggle-switch/toggle-switch";
import { Cookie, cookies } from "../ cookies-array";
import backButton from "../../../global/icons/back-button.svg";

interface CookiesModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  saveChanges: (cookies: Cookies) => void;
}

export interface Cookies {
  performanceCookies: boolean;
  functionalityCookies: boolean;
  marketingCookies: boolean;
}

const initialState = {
  performanceCookies: true,
  functionalityCookies: true,
  marketingCookies: true,
};

export function CookiesModal({ open, setOpen, saveChanges }: CookiesModalProps) {
  const [category, setCategory] = useState("");
  const [sortedCookies, setSortedCookies] = useState<Cookie[]>([]);
  const [allCookies, setAllCookies] = useState<Cookies>(initialState);

  const cookiesToDisplay = (category: string) => {
    setCategory(category);
    setSortedCookies(cookies.filter((cookie) => cookie.category === category));
  };

  const goBack = () => {
    setCategory("");
    setSortedCookies([]);
  };

  return (
    <section className='cookies-modal'>
      <div className='cookies-modal__window'>
        <div className='window-header'>
          <img src={logo} alt='Logo' className='window-header__logo' />
          <img src={close} alt='Close icon' className='window-header__close' onClick={() => setOpen(!open)} />
        </div>
        {!category && (
          <div className='window-body'>
            <p className='window-body__title'>Manage your cookie preferences</p>
            <p className='window-body__plain-text'>
              Cookies are small text files that websites place on your device as you are browsing. This information
              might be about you, your preferences or your device. The information does not usually directly identify
              you, but it can improve your browsing experience on our website. Because we respect your right to privacy,
              you can choose to opt out of certain cookies. Note that this may impact your experience of the site and
              the services we offer.
            </p>
            <div className='window-body__sub-header-container'>
              <p className='window-body__plain-text'>
                <b>Strictly necessary cookies</b>
              </p>
              <p className='window-body__plain-text' style={{ color: "#EC6664" }}>
                Mandatory
              </p>
            </div>
            <p className='window-body__plain-text'>
              These cookies are essential for you to browse the website and use its features, such as setting your
              privacy preferences, logging in, or filling in forms (which is why you can’t opt out of them).
            </p>
            <Button className='window-body__button' onClick={() => cookiesToDisplay("Strictly necessary cookies")}>
              <p className='window-body__plain-text' style={{ color: "#E48354" }}>
                Cookies details
              </p>
            </Button>
            <div className='window-body__sub-header-container'>
              <p className='window-body__plain-text'>
                <b>Performance cookies</b>
              </p>
              <ToggleSwitch
                id='performance'
                checked={allCookies.performanceCookies}
                onChange={() => setAllCookies({ ...allCookies, performanceCookies: !allCookies.performanceCookies })}
              />
            </div>
            <p className='window-body__plain-text'>
              These cookies allow us to track visits, traffic sources and clicks so we can measure and improve the
              performance of our website.
            </p>
            <Button className='window-body__button' onClick={() => cookiesToDisplay("Performance cookies")}>
              <p className='window-body__plain-text' style={{ color: "#E48354" }}>
                Cookies details
              </p>
            </Button>
            <div className='window-body__sub-header-container'>
              <p className='window-body__plain-text'>
                <b>Functionality cookies</b>
              </p>
              <ToggleSwitch
                id='functionality'
                checked={allCookies.functionalityCookies}
                onChange={() =>
                  setAllCookies({ ...allCookies, functionalityCookies: !allCookies.functionalityCookies })
                }
              />
            </div>
            <p className='window-body__plain-text'>
              These cookies allow a website to remember your personal preferences (e.g. what language setting you
              prefer), and ensure that all of our services function properly. These cookies may be set by us or
              third-party providers that we work with.
            </p>
            <Button className='window-body__button' onClick={() => cookiesToDisplay("Marketing cookies")}>
              <p className='window-body__plain-text' style={{ color: "#E48354" }}>
                Cookies details
              </p>
            </Button>
            <div className='window-body__sub-header-container'>
              <p className='window-body__plain-text'>
                <b>Marketing cookies</b>
              </p>
              <ToggleSwitch
                id='marketing'
                checked={allCookies.marketingCookies}
                onChange={() => setAllCookies({ ...allCookies, marketingCookies: !allCookies.marketingCookies })}
              />
            </div>
            <p className='window-body__plain-text'>
              These cookies track your online activity to help our advertising partners deliver more targeted ads or to
              limit how many times you see an ad, based on your browsing behavior and internet device.
            </p>
            <Button className='window-body__button' onClick={() => cookiesToDisplay("Marketing cookies")}>
              <p className='window-body__plain-text' style={{ color: "#E48354" }}>
                Cookies details
              </p>
            </Button>
          </div>
        )}
        {category && (
          <div className='window-body'>
            <div className='window-body__sub-header-container--start'>
              <img
                src={backButton}
                alt='Back button icon'
                className='window-body__back-button'
                onClick={() => goBack()}
              />
              <p className='window-body__title'>Cookies List</p>
            </div>
            {sortedCookies.map((cookie, index) => (
              <div className='window-body__cookie-container' key={index}>
                <div className='description'>
                  <p className='text'>Name:</p>
                  <p className='text'>Host:</p>
                  <p className='text'>Duration:</p>
                  <p className='text'>Type:</p>
                  <p className='text'>Category:</p>
                  <p className='text'>Description:</p>
                </div>
                <div className='cookie'>
                  <p className='text'>{cookie.name}</p>
                  <p className='text'>{cookie.host}</p>
                  <p className='text'>{cookie.duration}</p>
                  <p className='text'>{cookie.type}</p>
                  <p className='text'>{cookie.category}</p>
                  <p className='text'>{cookie.description}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className='window-footer'>
          <Button onClick={() => saveChanges(allCookies)} className='window-footer__button'>
            <p className='window-footer__button__text'>Save all changes</p>
          </Button>
        </div>
      </div>
    </section>
  );
}

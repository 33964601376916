export interface Cookie {
  name: string;
  host: string;
  duration: string;
  type: string;
  category: string;
  description?: string;
}

export const cookies: Cookie[] = [
  {
    name: "LaylaConsent",
    host: "www.laylaelectric.com",
    duration: "365 Days",
    type: "First Party",
    category: "Strictly necessary cookies",
    description:
      "It stores information about the categories of cookies the site uses and whether visitors have given or withdrawn consent for the use of each category. This enables site owners to prevent cookies in each category from being set in the users browser, when consent is not given. The cookie has a normal lifespan of one year, so that returning visitors to the site will have their preferences remembered. It contains no information that can identify the site visitor.",
  },
  // {
  //   name: "OptanonConsent",
  //   host: "trustpilot.com",
  //   duration: "365 Days",
  //   type: "First Party",
  //   category: "Strictly necessary cookies",
  //   description:
  //     "This cookie is set by the cookie consent solution from OneTrust. It stores information about the categories of cookies the site uses and whether visitors have given or withdrawn consent for the use of each category. This enables site owners to prevent cookies in each category from being set in the users browser, when consent is not given. The cookie has a normal lifespan of one year, so that returning visitors to the site will have their preferences remembered. It contains no information that can identify the site visitor.",
  // },
  // {
  //   name: "TP.uuid",
  //   host: "trustpilot.com",
  //   duration: "6430 Days",
  //   type: "First Party",
  //   category: "Strictly necessary cookies",
  // },
  // {
  //   name: "_hjFirstSeen",
  //   host: "trustpilot.com",
  //   duration: "A few seconds",
  //   type: "First Party",
  //   category: "Performance cookies",
  //   description:
  //     "Identifies a new user's first session on a website, indicating whether or not Hotjar's seeing this user for the first time.",
  // },
  // {
  //   name: "__asc",
  //   host: "trustpilot.com",
  //   duration: "A few seconds",
  //   type: "Third Party",
  //   category: "Performance cookies",
  //   description: "This cookie is used to collect information on consumer behavior, which is sent to Alexa Analytics.",
  // },
  // {
  //   name: "amplitude_id_67f7b7e6c8cb1b558b0c5bda2f747b07trustpilot.com",
  //   host: "trustpilot.com",
  //   duration: "3650 Days",
  //   type: "Third Party",
  //   category: "Performance cookies",
  //   description: "This cookie is used to collect information on consumer behavior, which is sent to Alexa Analytics.",
  // },
  // {
  //   name: "_hjAbsoluteSessionInProgress",
  //   host: "trustpilot.com",
  //   duration: "A few seconds",
  //   type: "Third Party",
  //   category: "Performance cookies",
  //   description:
  //     "This cookie is used by HotJar to detect the first pageview session of a user. This is a True/False flag set by the cookie.",
  // },
  // {
  //   name: "_hjAbsoluteSessionInProgress",
  //   host: "trustpilot.com",
  //   duration: "A few seconds",
  //   type: "First Party",
  //   category: "Performance cookies",
  //   description:
  //     "This cookie is used by HotJar to detect the first pageview session of a user. This is a True/False flag set by the cookie.",
  // },
  // {
  //   name: "amplitude_idundefinedtrustpilot",
  //   host: "trustpilot.com",
  //   duration: "Session",
  //   type: "Third Party",
  //   category: "Performance cookies",
  // },
  // {
  //   name: "__auc",
  //   host: "trustpilot.com",
  //   duration: "366 Days",
  //   type: "Third Party",
  //   category: "Performance cookies",
  //   description: "This cookie is used to collect information on consumer behavior, which is sent to Alexa Analytics.",
  // },
  // {
  //   name: "_hjSession_xxxxxx",
  //   host: "trustpilot.com",
  //   duration: "A few seconds",
  //   type: "First Party",
  //   category: "Performance cookies",
  //   description:
  //     "A cookie that holds the current session data. This ensues that subsequent requests within the session window will be attributed to the same Hotjar session.",
  // },
  // {
  //   name: "_hjSessionUser_xxxxxx",
  //   host: "trustpilot.com",
  //   duration: "364 Days",
  //   type: "First Party",
  //   category: "Performance cookies",
  //   description:
  //     "Hotjar cookie that is set when a user first lands on a page with the Hotjar script. It is used to persist the Hotjar User ID, unique to that site on the browser. This ensures that behavior in subsequent visits to the same site will be attributed to the same user ID.",
  // },
  // {
  //   name: "ajs_anonymous_id",
  //   host: "trustpilot.com",
  //   duration: "365 Days",
  //   type: "First Party",
  //   category: "Performance cookies",
  //   description:
  //     "These cookies are generally used for Analytics and help count how many people visit a certain site by tracking if you have visited before. This cookie has a lifespan of 1 year.",
  // },
];

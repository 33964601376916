import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../routes";
import "./App.scss";

function App() {
  return (
    <Routes>
      {routes.map(({ path, Component }, key) => (
        <Route path={path} key={key} element={<Component />} />
      ))}
    </Routes>
  );
}

export default App;

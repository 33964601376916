import React from "react";
import { CookiesBanner } from "../cookies/cookies";
import { Footer } from "./footer/footer";
import "./layout.scss";
import { Navbar } from "./navbar/navbar";

export interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps): JSX.Element {
  return (
    <div className="page">
      <header className="page__header">
        <Navbar />
      </header>

      <main className="page__content">
        <div className="page__content__paddings">{children}</div>
        <CookiesBanner />
      </main>

      <footer className="page__footer">
        <Footer />
      </footer>
    </div>
  );
}

export default Layout;

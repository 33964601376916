import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Cookies, CookiesModal } from "./cookies-modal/cookies-modal";
import "./cookies.scss";

export function CookiesBanner(): JSX.Element {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    openModal && (document.body.style.overflow = "hidden");
    !openModal && (document.body.style.overflow = "unset");
  }, [openModal]);

  const saveChanges = (cookies: Cookies) => {
    setOpenModal(false);

    if (!cookies.performanceCookies) {
      console.log("performance delete");
    }
    if (!cookies.marketingCookies) {
      console.log("marketing delete");
    }
    if (!cookies.functionalityCookies) {
      console.log("functionality delete");
    }

    console.log("accepted");
  };

  const buttonStyle = {
    background: "#EB996E",
    padding: "10px 25px",
    margin: "0px",
    borderRadius: "14px",
    color: "white",
    fontFamily: "Noto Sans",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
  };

  return (
    <>
      <section className='cookies'>
        <CookieConsent
          cookieName='LaylaConsent'
          cookieValue={{ datastamp: new Date() }}
          containerClasses={"cookies__banner"}
          buttonWrapperClasses={"agree-button-container"}
          buttonStyle={buttonStyle}
          contentClasses={"description"}
          buttonText={"Got it"}
        >
          <div className='description__text'>
            <h1 className='description__title'>We use cookies</h1>
            We use cookies to personalize content and ads, to provide social media features, and to analyze our traffic.
            We also share information about your use of our site with our partners in social media, advertising, and
            analytics. By continuing to use our website, you accept the use of all cookies. You can always access and
            change your cookie preferences in the footer of this website.
          </div>
          <button className='description__button' onClick={() => setOpenModal(true)}>
            <p className='text'>Cookie preferences</p>
          </button>
        </CookieConsent>
      </section>
      {openModal && <CookiesModal open={openModal} setOpen={setOpenModal} saveChanges={saveChanges} />}
    </>
  );
}

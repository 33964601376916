import React from "react";
import { NavLink } from "react-router-dom";
import "./side-bar.scss";

interface Props {
  menuItems: { id: number; title: string; url: string }[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Sidebar({ menuItems, open, setOpen }: Props) {
  if (!open) {
    return <></>;
  }

  return (
    <section className='side-bar'>
      <ul className='side-bar__menu-items'>
        {menuItems.map((menuItem) => (
          <NavLink
            key={menuItem.id}
            to={menuItem.url}
            className={({ isActive }) => "item" + (!isActive ? "" : "--active")}
            onClick={() => setOpen(false)}
          >
            {menuItem.title}
          </NavLink>
        ))}
      </ul>
    </section>
  );
}

import { lazy } from "react";

const Careers = lazy(() => import("./pages/careers"));
const Home = lazy(() => import("./pages/home"));
const JoinNow = lazy(() => import("./pages/join-now"));
const Pricing = lazy(() => import("./pages/pricing"));
const PrivacyPolicy = lazy(() => import("./pages/privacy-policy"));
const Product = lazy(() => import("./pages/product"));
const ProductManual = lazy(() => import("./pages/product-manual"));
const RefundPolicy = lazy(() => import("./pages/refund-policy"));
const Resources = lazy(() => import("./pages/resources"));
const Solutions = lazy(() => import("./pages/solutions"));
const TermsOfUse = lazy(() => import("./pages/terms-of-use"));
const WorkInProgress = lazy(() => import("./pages/work-in-progress"));
const Discover = lazy(() => import("./pages/solutions/[id]/index"));
const YoloFixCaseStudy = lazy(() => import("./pages/success-stories/yolofix-case-study"));
const NimbleCaseStudy = lazy(() => import("./pages/success-stories/nimble-case-study"));
const HvCaseStudy = lazy(() => import("./pages/success-stories/hv-case-study"));
const SuccessStories = lazy(() => import("./pages/success-stories/index"));
const Demo = lazy(() => import("./pages/demo"));
const TutorialVideos = lazy(() => import("./pages/tutorial-videos"));
const Blog = lazy(() => import("./pages/blog"));
const EmpoweringShortRental = lazy(() => import("./pages/blog/EmpoweringShortRental"));
const GreenHosting = lazy(() => import("./pages/blog/GreenHosting"));
const SmartPowerManagement = lazy(() => import("./pages/blog/SmartPowerManagement"));
const PrivacyAndProtection = lazy(() => import("./pages/blog/PrivacyAndProtection"));
const SavingThousands = lazy(() => import("./pages/blog/SavingThousands"));
const ElevatingGuest = lazy(() => import("./pages/blog/ElevatingGuest"));
const ElevatingShortTerm = lazy(() => import("./pages/blog/ElevatingShortTerm"));
const LaylaSensors = lazy(() => import("./pages/blog/LaylaSensors"));

export default [
  { path: "*", name: "NotFound", Component: WorkInProgress },
  { path: "/", name: "Home", Component: Home },
  { path: "/product", name: "Product", Component: Product },
  { path: "/pricing", name: "Pricing", Component: Pricing },
  { path: "/join-now", name: "JoinNow", Component: JoinNow },
  { path: "/resources", name: "Resources", Component: Resources },
  { path: "/solutions", name: "Solutions", Component: Solutions },
  { path: "/terms-of-use", name: "TermsOfUse", Component: TermsOfUse },
  { path: "/refund-policy", name: "RefundPolicy", Component: RefundPolicy },
  { path: "/product-manual", name: "ProductManual", Component: ProductManual },
  { path: "/privacy-policy", name: "PrivacyPolicy", Component: PrivacyPolicy },
  { path: "/careers", name: "Careers", Component: Careers },
  { path: "/solutions/:id", name: "Discover", Component: Discover },
  { path: "/success-stories/case-study-yolofix-management", name: "YoloFix", Component: YoloFixCaseStudy },
  {
    path: "/success-stories/how-nimble-hous-achieved-portfolio-growth-cost-savings-and-increased-profits-with-layla",
    name: "Nimble",
    Component: NimbleCaseStudy,
  },
  {
    path: "/success-stories/how-hv-property-management-saves-thousands-on-houston-rentals-and-enhances-home-security-with-layla",
    name: "HVCaseStudy",
    Component: HvCaseStudy,
  },
  {
    path: "/success-stories",
    name: "SuccessStories",
    Component: SuccessStories,
  },
  {
    path: "/demo",
    name: "Demo",
    Component: Demo,
  },
  {
    path: "/tutorial-videos",
    name: "TutorialVideos",
    Component: TutorialVideos,
  },
  {
    path: "/blog",
    name: "Blog",
    Component: Blog,
  },
  {
    path: "/blog/empowering-the-short-term-rental-experience",
    name: "EmpoweringShortRental",
    Component: EmpoweringShortRental,
  },
  {
    path: "/blog/eco-mode-feature-for-cost-savings-and-climate-action",
    name: "",
    Component: GreenHosting,
  },
  {
    path: "/blog/smart-power-management",
    name: "SmartPowerManagement",
    Component: SmartPowerManagement,
  },
  {
    path: "/blog/privacy-and-protection",
    name: "PrivacyAndProtection",
    Component: PrivacyAndProtection,
  },
  {
    path: "/blog/saving-thousands-with-layla-sensors",
    name: "SavingThousands",
    Component: SavingThousands,
  },
  {
    path: "/blog/elevating-guest-experiences-with-layla-sensors",
    name: "ElevatingGuest",
    Component: ElevatingGuest,
  },
  {
    path: "/blog/elevating-short-term-rentals",
    name: "ElevatingShortTerm",
    Component: ElevatingShortTerm,
  },
  {
    path: "/blog/layla-electric-sensors",
    name: "LaylaSensors",
    Component: LaylaSensors,
  },
];

import React from "react";
import "./loading-component.scss";

export function LoadingComponent() {
  return (
    <section className="loading-container">
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="shadow"></div>
      <div className="shadow"></div>
      <div className="shadow"></div>
    </section>
  )
}
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { SvgIcon } from "../../svg-icon/svg-icon";
import logo from "../../../global/icons/logo-mobile.svg";
import "./footer.scss";

export function Footer() {
  const navigate = useNavigate();

  return (
    <section className='footer'>
      <div className='row'>
        <div className='col-2'>
          <div className='footer__logo'>
            <img src={logo} alt='Logo' className='logo' />
          </div>
        </div>
        <div className='col-7'>
          <div className='footer__info'>
            <div className='info'>
              <Link to={"/product"} className='info__title'>
                Product
              </Link>
              <Link to={"/product-manual"} className='info__plain-text'>
                Product Manual
              </Link>
              <a href={"https://api-doc-357ca.web.app/"} target='_blank' className='info__plain-text' rel='noreferrer'>
                API Doc
              </a>
              <a
                href='/pricing'
                onClick={() => navigate("/pricing", { state: "coreFeaturesRef" })}
                className='info__plain-text'
              >
                Features
              </a>
              <Link to={"/integrations"} className='info__plain-text'>
                Integrations
              </Link>
              <Link to={"/demo"} className='info__plain-text'>
                Demo
              </Link>
            </div>
            <div className='info'>
              <Link to={"/careers"} className='info__title'>
                Company
              </Link>
              <Link to={"/careers"} className='info__plain-text'>
                About Us
              </Link>
              <Link to={"/press"} className='info__plain-text'>
                Press
              </Link>
              <Link to={"/careers"} className='info__plain-text'>
                Careers
              </Link>
            </div>
            <div className='info'>
              <Link to={"/resources"} className='info__title'>
                Resources
              </Link>
              <a href='/' onClick={() => navigate("/", { state: "testimonialsRef" })} className='info__plain-text'>
                Testimonials
              </a>
              <Link to={"/refund-policy"} className='info__plain-text'>
                Refund Policy
              </Link>
              <Link to={"/success-stories"} className='info__plain-text'>
                Success Stories
              </Link>
              <Link to={"/tutorial-videos"} className='info__plain-text'>
                Tutorial Videos
              </Link>
              <Link to={"/blog"} className='info__plain-text'>
                Blog
              </Link>
            </div>
            <div className='info'>
              <a href='mailto:support@laylaelectric.com' className='info__title'>
                Contact & <br /> Support
              </a>
              <a href='mailto:info@laylaelectric.com' className='info__plain-text'>
                Contact Us
              </a>
              <a href='mailto:support@laylaelectric.com' className='info__plain-text'>
                Support Email
              </a>
              <Link to={"/terms-of-use"} className='info__plain-text'>
                Terms of Use
              </Link>
              <Link to={"/privacy-policy"} className='info__plain-text'>
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        <div className='col-3'>
          <div className='footer__socials'>
            <SvgIcon name='facebook' className='icon' />
            <SvgIcon name='instagram' className='icon' />
            <SvgIcon name='twitter' className='icon' />
            <SvgIcon name='linkedin' className='icon' />
            <SvgIcon name='youtube' className='icon' />
          </div>
        </div>
      </div>
    </section>
  );
}
